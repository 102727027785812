<template>
  <div class="login-container"></div>
</template>

<script>
/* eslint-disable */

export default {
  name: 'Login',
  data() {
    return {
      userid: '',
      redirect: '',
    };
  },
  watch: {
  },
  created() {
    // window.addEventListener('hashchange', this.afterQRScan)
  },
  beforeMount() {
    this.init();
  },
  destroyed() {
    // window.removeEventListener('hashchange', this.afterQRScan)
  },
  methods: {
    init() {
      if (this.$route.query) {
        if (this.$route.query.loginId) {
          this.loginId = this.$route.query.loginId;
        }
        
        if (this.$route.query.redirect) {
          this.redirect = this.$route.query.redirect;
        }
      }

      this.loading = true;
      this.$store
        .dispatch('sso', { loginId: this.loginId })
        .then(() => {
          this.loading = false;
          this.$router.push({ path: this.redirect || '/' });
        })
        .catch(error => {
          this.loading = false;
          this.$store
            .dispatch('LogOut')
            .then(() => {
              this.$router.push({ path: '/ssoFail' });
            })
            .catch(err => {
              this.$router.push({ path: '/ssoFail' });
            });
          this.$comm.movePage(this.$router, '/404');
        });
    },
  },
};
</script>